import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollTop = (): null => {
  const location = useLocation();
  //console log for testing
  useEffect(() => {
    console.log(location, 'scroll to top triggered');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollTop;
